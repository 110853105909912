import { Alert, Skeleton, Stack } from '@mui/material'
import axios from 'axios'
import useNotification from 'hooks/useNotifications'
import { useEffect, useState } from 'react'
import AssistantContent from './AssistantContent'
import Icon from 'components/MioCandidate/Icon'

interface Article {
  id: number
  url: string
  html_url: string
  author_id: number
  comments_disabled: boolean
  draft: boolean
  promoted: boolean
  position: number
  vote_sum: number
  vote_count: number
  section_id: number
  created_at: string // ISO 8601 format
  updated_at: string // ISO 8601 format
  name: string
  title: string
  source_locale: string
  locale: string
  outdated: boolean
  outdated_locales: string[]
  edited_at: string // ISO 8601 format
  user_segment_id: number | null
  permission_group_id: number
  content_tag_ids: number[]
  label_names: string[]
  body: string
}

interface AssistantArticleProps {
  articleId: string
  open?: boolean
  onLoad?: (article?: Article) => void
}
export default function AssistantArticle(props: AssistantArticleProps) {
  //teste
  const { articleId, open, onLoad } = props
  const notifications = useNotification()
  const [article, setArticle] = useState<Article | undefined>(undefined)
  const [error, setError] = useState<false | undefined | string>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    async function scraping() {
      setLoading(true)
      try {
        const cachedData = localStorage.getItem(articleId)
        if (cachedData) {
          const parsedData = JSON.parse(cachedData)
          const now = new Date().getTime()
          if (now - parsedData.timestamp < 10 * 60 * 1000) {
            // 10 minutes in milliseconds
            setArticle(parsedData.article)
            setLoading(false)
            return
          }
        }
        const response = await axios.get<{ article: Article }>(
          `https://datacsistemashelp.zendesk.com/api/v2/help_center/pt-br/articles/${articleId}`,
        )
        const fetchedArticle = response.data.article
        setArticle(fetchedArticle)
        localStorage.setItem(
          articleId,
          JSON.stringify({
            article: fetchedArticle,
            timestamp: new Date().getTime(),
          }),
        )
      } catch (err) {
        setError(notifications.extractAxiosError(err))
      } finally {
        setLoading(false)
      }
    }
    if (articleId) {
      scraping()
    }
  }, [articleId])

  useEffect(() => {
    if (onLoad) {
      onLoad(article)
    }
  }, [article])

  return (
    <Stack>
      {error && (
        <Alert severity="error">Não foi possível carregar o conteúdo</Alert>
      )}
      {loading && <Skeleton variant="rectangular" width={426} height={40} />}
      {article && (
        <AssistantContent
          title={`${article?.title || 'carregando'}`}
          open={open}
          icon={<Icon.Docs />}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                article?.body
                  ?.replace('width="560"', 'width="412px"')
                  .replace('height="315"', 'height="240px"')
                  .replace('h2', 'h3') || '',
            }}
          />
        </AssistantContent>
      )}
    </Stack>
  )
}
