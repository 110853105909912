import { Skeleton, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import YouTube, { YouTubeProps } from 'react-youtube'

interface AssistantYoutubePlayerProps {
  videoId: string
  title?: string
}

export default function AssistantYoutubePlayer({
  videoId,
  title,
}: AssistantYoutubePlayerProps) {
  // function onPlayerReady(event: YouTubeProps['onReady']) {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo()
  // }

  const [loading, setLoading] = useState<boolean>(true)

  const onPlayerReady: YouTubeProps['onReady'] = () => {
    setLoading(false)
  }
  return (
    <Stack
      justifyContent="center"
      alignContent="center"
      sx={{ width: '100%' }}
      // className="border"
    >
      {title && (
        <Typography variant="h6" fontWeight="bolder">
          {title}
        </Typography>
      )}
      {loading && <Skeleton variant="rectangular" width={412} height={240} />}
      <div
        style={{
          display: loading ? 'none' : 'block',
        }}
      >
        <YouTube
          loading="Carregando"
          onReady={onPlayerReady}
          videoId={videoId}
          opts={{
            width: '412px',
            height: '240px',
          }}
        />
      </div>
    </Stack>
  )
}
