import {
  Collapse,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import ItensTabelaPrecoTransporter, {
  ProdutoTransporter,
} from 'components/Transporters/ItensTabelaPrecoTransporter'
import React, { useEffect, useRef, useState } from 'react'
import Form from './Form'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import {
  ItemPedidoModel,
  PedidoModel,
  useSubmitCodigoBarras,
  useSubmitItemPedido,
} from 'hooks/queries/usePedidos'
import * as yup from 'yup'
import { isValidNumber, useValidations } from '@data-c/hooks'
import FormVariacao1 from './FormVariacao1'
import MioIcon from 'components/MioCandidate/Icon'
import useNotification from 'hooks/useNotifications'
import queryClient from 'services/query-client'
import beepMp3 from 'assets/audio/beep.mp3'
import errorBeepMp3 from 'assets/audio/error_beep.mp3'
import FichaClienteTransporter from 'components/Transporters/FichaClienteTransporter'

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  quantidade: yup
    .number()
    .nullable(true)
    .required('Informe a quantidade')
    .moreThan(0, 'A Quantidade deve ser superior a zero(0)')
    .transform(transform),
  precoUnitarioLiquido: yup
    .number()
    .nullable(true)
    .required('Informe o Preço de Venda')
    .moreThan(0, 'O Preço de Venda deve ser maior do que R$0,00')
    .transform(transform),
  percentualIpi: yup
    .number()
    .nullable(true)
    .when('$calculaImposto', (calculaImposto, schema) => {
      if (calculaImposto) {
        return yup
          .number()
          .nullable(true)
          .required('Informe o IPI (%)')
          .transform(transform)
      }
      return schema
    }),
  precoUnitarioIpi: yup
    .number()
    .nullable(true)
    .when('$calculaImposto', (calculaImposto, schema) => {
      if (calculaImposto) {
        return yup
          .number()
          .nullable(true)
          .required('Informe o IPI (R$)')
          .transform(transform)
      }
      return schema
    }),
  fatorPrecificacao: yup
    .object()
    .nullable(true)
    .when('$usaFator', (usaFator, schema) => {
      if (usaFator) {
        return yup
          .object()
          .shape({
            uuid: yup.string().required('Informe o Fator de Precificação'),
          })
          .nullable(true)
          .required('Informe o Fator de Precificação')
      }
      return schema
    }),
  precoTotalLiquido: yup
    .number()
    .nullable(true)
    .when('quantidade', (quantidade, schema) => {
      if (quantidade > 0) {
        return yup
          .number()
          .nullable(true)
          .required('O Preço Total não pode ser um valor vazio')
          .moreThan(0, 'Preço Total deve ser maior do que R$0,00')
          .transform(transform)
      }
      return schema
    }),
})

export interface AdicionarItemProps {
  tabelaPrecoUuid: string
  pedido: PedidoModel
  calculaImposto?: boolean
  value?: ItemPedidoModel | null
  onReset?: () => void
}

function playBeep() {
  const beep = new Audio(beepMp3)
  beep.play()
}

function playErrorBeep() {
  const beep = new Audio(errorBeepMp3)
  beep.play()
}

export default function AdicionarItem(props: AdicionarItemProps) {
  const {
    tabelaPrecoUuid,
    pedido,
    calculaImposto = false,
    value,
    onReset,
  } = props
  const barCodeRef = useRef<HTMLInputElement>(null)
  const { data: configuracoes } = useQuery()
  const [insertMode, setInsertMode] = useState<
    'BAR_CODE' | 'DEFAULT' | 'FICHA'
  >('DEFAULT')
  const [prontaEntrega, setProntaEntrega] = useState<boolean>(false)
  const [barcode] = useState<string | null>()
  const [item, setItem] = useState<ItemPedidoModel | null>()
  const [selectedItem, setSelectedItem] = useState<ProdutoTransporter | null>()
  const notifications = useNotification()
  const { setValidationErrors, validationProps } = useValidations()

  const itemSearchRef = useRef<HTMLInputElement>(null)

  const { mutateAsync, isLoading } = useSubmitItemPedido()
  const {
    mutateAsync: mutateAsyncCodigoBarras,
    // isLoading: isLoadingCodigoBarras,
  } = useSubmitCodigoBarras()

  const usaFator = Boolean(configuracoes?.usa_fator)

  function handleChangeProduto(
    produto: ProdutoTransporter | null,
    prontaEntrega?: boolean,
  ) {
    if (!Boolean(produto?.uuid) && produto) {
      produto.percentualDesconto = pedido.percentualDesconto || 0
    }

    setSelectedItem(produto)
    setItem(produto)
    setProntaEntrega(Boolean(prontaEntrega))
  }

  function handleSave(formValue: ItemPedidoModel) {
    setValidationErrors(null)
    schema
      .validate(formValue, {
        abortEarly: false,
        context: {
          usaFator: Boolean(usaFator),
          calculaImposto: Boolean(calculaImposto),
        },
      })
      .then(async () => {
        try {
          await mutateAsync({
            pedidoUuid: pedido?.uuid || '',
            item: formValue,
          })
          setItem(null)
          // setSelectedItem(null)
          if (itemSearchRef) {
            itemSearchRef?.current?.focus()
            itemSearchRef?.current?.setSelectionRange(
              0,
              itemSearchRef?.current.selectionEnd,
            )
          }
          if (onReset) onReset()
        } catch (err) {}
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    if (value) {
      setItem(value)
    }
  }, [value])

  function handleClose() {
    setItem(null)
    setSelectedItem(null)
    if (itemSearchRef) {
      itemSearchRef?.current?.focus()
      itemSearchRef?.current?.setSelectionRange(
        0,
        itemSearchRef?.current.selectionEnd,
      )
    }
    if (onReset) onReset()
  }

  function handleBarcode(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('barcode', e.target.value)
  }

  async function handleAddBarrcode(e: React.FocusEvent<HTMLInputElement>) {
    const _barcode = e.target.value
    if (!_barcode) return
    try {
      await mutateAsyncCodigoBarras({
        pedidoUuid: pedido.uuid,
        codigoBarras: _barcode,
        prontaEntrega,
      })
      if (barCodeRef.current) {
        barCodeRef.current.value = ''
        barCodeRef.current.focus()
      }

      playBeep()
      notifications.notifySuccess(`Operação realizada com sucesso`)
      queryClient.invalidateQueries(['PEDIDO'])
    } catch (err) {
      playErrorBeep()
      notifications.notifyException(err)
    }
  }

  return (
    <Stack flexGrow={1}>
      <Stack direction={'row'} spacing={1} alignItems="center">
        <div>
          <ToggleButtonGroup
            size="small"
            value={insertMode}
            exclusive
            onChange={(_, value) => {
              if (!value) return
              setInsertMode(value)
            }}
          >
            <ToggleButton value="DEFAULT">
              <MioIcon.TextCursor />
            </ToggleButton>
            <ToggleButton value="BAR_CODE">
              <MioIcon.BarCode />
            </ToggleButton>
            {!configuracoes?.usa_pedido_pronta_entrega && (
              <ToggleButton value="FICHA">
                <MioIcon.History />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </div>
        <Stack flexGrow={1}>
          {Boolean(value) ? (
            <TextField
              value={`${value?.produto?.codigo} - ${value?.produto?.nome}`}
            />
          ) : insertMode === 'DEFAULT' ? (
            <ItensTabelaPrecoTransporter
              disabled={Boolean(value) || Boolean(!pedido.tabelaPreco)}
              value={selectedItem || null}
              tabelaPrecoUuid={tabelaPrecoUuid}
              label={''}
              placeholder="Digite o nome ou o código do produto para adicioná-lo ao pedido"
              onChange={(value, prontaEntrega) => {
                handleChangeProduto(value, prontaEntrega)
              }}
              // onFocus={handleFocus}
              // onBlur={handleFocus}
              inputRef={itemSearchRef}
              prontaEntrega={prontaEntrega}
            />
          ) : insertMode === 'BAR_CODE' ? (
            <TextField
              inputRef={barCodeRef}
              disabled={Boolean(!pedido.tabelaPreco)}
              autoFocus
              name="barcode"
              value={barcode}
              onChange={handleBarcode}
              onBlur={handleAddBarrcode}
              placeholder="Faça leitura do código de barras para adicionar o produto ao pedido"
              slotProps={{
                input: {
                  endAdornment: Boolean(
                    configuracoes?.usa_pedido_pronta_entrega,
                  ) ? (
                    <FormControlLabel
                      sx={{
                        whiteSpace: 'nowrap',
                        border: (theme) => `1px solid ${theme.palette.border}`,
                        borderRadius: '90px',
                        height: '26px',
                        pl: 1,
                        mr: 1,
                      }}
                      checked={prontaEntrega}
                      onChange={(e) => {
                        //@ts-ignore
                        setProntaEntrega(e.target.checked)
                      }}
                      control={<Switch color="primary" />}
                      label="Pronta Entrega"
                      labelPlacement="start"
                    />
                  ) : null,
                },
              }}
            />
          ) : insertMode === 'FICHA' ? (
            <FichaClienteTransporter
              placeholder="Produtos que o cliente já comprou anteriormente"
              clienteUuid={pedido?.cliente?.uuid || ''}
              tabelaPrecoUuid={pedido?.tabelaPrecoUuid || ''}
              value={selectedItem || null}
              onChange={(value) => {
                handleChangeProduto(value, prontaEntrega)
              }}
              inputRef={itemSearchRef}
            />
          ) : (
            <div>Nenhum método de inserção definido</div>
          )}
        </Stack>
      </Stack>
      <Collapse
        sx={{
          // background: (theme) => theme.palette.background.paper,
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
          borderRight: (theme) => `1px solid ${theme.palette.border}`,
          borderLeft: (theme) => `1px solid ${theme.palette.border}`,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
          p: Boolean(item) ? 1 : 0,
        }}
        in={Boolean(item)}
      >
        {item && item?.produto?.tipoVariacao === 'NENHUMA' && (
          <Form
            autoFocus={true}
            mixProdutos={pedido.mixProdutos}
            fatorFinanceiro={pedido.condicaoPagamento?.fator || 1}
            isSubmitting={isLoading}
            validationProps={validationProps}
            item={item as ItemPedidoModel}
            calculaImposto={Boolean(calculaImposto)}
            onClose={handleClose}
            onSave={handleSave}
          />
        )}

        {item && item?.produto?.tipoVariacao === 'NIVEL_1' && (
          <FormVariacao1
            produto={item}
            pedido={pedido}
            onClose={handleClose}
            prontaEntrega={prontaEntrega}
          />
        )}
      </Collapse>
    </Stack>
  )
}
