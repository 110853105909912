import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { DialogContent, DialogContentText } from '@mui/material'

export interface ConfirmDialogProps {
  isOpen: boolean
  isLoading?: boolean
  onConfirm: () => void
  onCancel?: () => void
  close: () => void
  confirmLabel?: string
  cancelLabel?: string
  title?: string
  message?: string
  type?: 'info' | 'warning' | 'error' | 'success'
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    isOpen,
    isLoading,
    onConfirm,
    onCancel,
    close,
    confirmLabel = 'Excluir',
    cancelLabel = 'Cancelar',
    message = 'Você não poderá recuperar este registro após excluí-lo!',
    title = 'Excluir Registro Permanentemente?',
    type = 'error',
  } = props
  return (
    <Dialog
      type={type}
      title={title}
      open={isOpen}
      onClose={close}
      actions={
        <ButtonContainer>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              if (onCancel) {
                onCancel()
                return
              }
              close()
            }}
          >
            {cancelLabel}
          </Button>
          <Button
            isLoading={isLoading}
            variant="contained"
            color={type}
            onClick={(e) => {
              e.stopPropagation()
              onConfirm()
            }}
          >
            {confirmLabel}
          </Button>
        </ButtonContainer>
      }
    >
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
