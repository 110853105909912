import AssistantRoot from './AssistantRoot'
import AssistantYoutubePlayer from './AssistantYoutubePlayer'
import AssistantLink from './AssistantLink'
import AssistantArticle from './AssistantArticle'
import AssistantContent from './AssistantContent'

const Assistant = {
  Root: AssistantRoot,
  YoutubePlayer: AssistantYoutubePlayer,
  Link: AssistantLink,
  Article: AssistantArticle,
  Content: AssistantContent,
}

export default Assistant
