import { FormProvider } from '@data-c/providers'

import { Container } from '@mui/material'

import useVendedores from 'hooks/queries/useVendedores'

import Delete from 'components/Delete'

import Table from './components/Table'
import Filter from './components/Filter'
import Form from './components/Form'

import Content from 'components/Layouts/Content'

import MioFilter from 'components/MioCandidate/Filter'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function Vendedores() {
  const { useDelete } = useVendedores()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <MioFilter.Provider filterValues={{}}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Vendedores',
                  link: '/vendedores/lista',
                },
              ]}
            />
            <Filter />
            <Form view="dialog" />

            {/* <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28518211079316-Cadastro-de-Clientes" /> */}
          </ContentTitle.Root>

          {/* <PageTitle title="Vendedores">
            <ButtonContainer>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tabs vendedores"
              >
                <Tab label="Cadastro" value={0} />
                
              </Tabs>
              <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28565697149460-Cadastro-de-Vendedores" />
            </ButtonContainer>
          </PageTitle> */}

          <Content>
            <Table />
            <Delete onDelete={handleDelete} />

            {/* <TabPanel value={value} index={0}>
              <ButtonContainer sx={{ mb: 2 }}>
                <FilterProvider filterValues={filters}>
                  <Filter
                    onApplyFilters={(_appliedFilters) => {
                      setFilters(_appliedFilters)
                    }}
                  />
                </FilterProvider>
            
              </ButtonContainer>

              
            </TabPanel> */}
            {/* <TabPanel value={value} index={1}>
              <TabChavesDeAcesso />
            </TabPanel> */}
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
