import { Collapse, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import Icon from 'components/MioCandidate/Icon'

interface AssistantContentProps {
  open?: boolean
  icon?: React.ReactNode
  title: string
  children: React.ReactNode
}
export default function AssistantContent(props: AssistantContentProps) {
  const { open: _open, icon, children, title } = props
  const [open, setOpen] = useState<boolean>(Boolean(_open))

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {icon && icon}
          <Typography variant="h5" fontWeight="bolder">
            {title}
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            setOpen(!open)
          }}
        >
          <Icon.Expandable open={open} />
        </IconButton>
      </Stack>
      <Collapse in={open}>{children}</Collapse>
    </Stack>
  )
}
