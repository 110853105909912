import { TextField } from '@mui/material'

import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

import FilterForm from './FilterForm'
import CnpjCpfTextField from 'components/CnpjCpfTextField'
import { onlyNumbers } from '@data-c/hooks'

export default function Filter() {
  const { changeFilterValue, applyFilterValues, onChangeFilterValue } =
    useFilterApi<{
      plainQuery?: string
    }>()

  const { appliedValues, filterValues } = useFilter()
  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Vendedores"
        />

        <MioFilter.Chips>
          <MioFilter.Chip name="codigo" label={appliedValues?.codigo}>
            <TextField
              name="codigo"
              value={filterValues?.codigo || ''}
              label="Código"
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="usual" label={`${appliedValues?.usual}`}>
            <TextField
              name="usual"
              value={filterValues?.usual || ''}
              label="Nome"
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="cnpjcpf" label={`${appliedValues?.cnpjcpf}`}>
            <CnpjCpfTextField
              name="cnpjcpf"
              autoFocus
              value={filterValues?.cnpjcpf || ''}
              onChange={(e) => {
                const cnpjcpf = onlyNumbers(e.target.value)
                changeFilterValue('cnpjcpf', cnpjcpf)
              }}
              inputProps={{
                label: `CNPJ/CPF`,
              }}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
