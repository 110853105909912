import { Box, Drawer, IconButton, Stack } from '@mui/material'
import Icon from 'components/MioCandidate/Icon'
import React, { useState } from 'react'
import AssistantHeader from './AssistantHeader'
import Divider from 'components/Divider'

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   // width: theme?.mixins?.menu?.width || 64,
//   // width: '200px',
//   // borderRightStyle: 'none',
//   // whiteSpace: 'nowrap',
//   // boxSizing: 'border-box',
//   // ...(open && {
//   //   ...openedMixin(theme),
//   //   '& .MuiDrawer-paper': openedMixin(theme),
//   // }),
//   // ...(!open && {
//   //   ...closedMixin(theme),
//   //   '& .MuiDrawer-paper': closedMixin(theme),
//   // }),
// }))

// const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function Root({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <React.Fragment>
      <IconButton
        onClick={() => setOpen(!open)}
        color="primary"
        sx={{
          zIndex: 99,
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          border: 'none',
          backgroundColor: '#fff',
          '&:hover': { backgroundColor: '#efefef' },
        }}
      >
        <Icon.Help />
      </IconButton>
      <Drawer
        onClose={() => setOpen(!open)}
        anchor="right"
        open={open}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 3,
          '& .MuiDrawer-paper': {
            zIndex: (theme) => theme.zIndex.drawer + 3,
            overflow: 'scroll',
            scrollbarWidth: 'none',
            // width: '426px',
          },
        }}
      >
        {/* <Offset /> */}
        <Box sx={{ p: 1 }}>
          <AssistantHeader onClose={() => setOpen(!open)} />
        </Box>
        <Divider />
        <Stack sx={{ width: '426px', p: 1 }} spacing={1}>
          {children}
        </Stack>
      </Drawer>
    </React.Fragment>
  )
}
