import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import api from 'services/api'

interface AssistantLinkProps {
  url: string
}

export default function AssistantLink(props: AssistantLinkProps) {
  const { url } = props
  const [_, setMetaData] = useState()
  useEffect(() => {
    async function scraping() {
      const response = await api.get(`/scraping`, {
        params: { url: url },
      })
      setMetaData(response.data)
    }
    if (url) {
      scraping()
    }
  }, [url])

  return <Stack></Stack>
}
