import { IconButton, MenuItem, TextField, TextFieldProps } from '@mui/material'
import ConfirmDialog from 'components/Displays/ConfirmDialog'
import useDesconto, { DescontoModel } from 'hooks/queries/useDesconto'
import useDialog from 'hooks/useDialog'
import React from 'react'
import Icon from 'components/MioCandidate/Icon'

export type DropDownDescontosProps = {
  onChange: (value: DescontoModel | undefined) => void
  value?: DescontoModel | null
} & Omit<TextFieldProps, 'onChange'>

export default function DropDownDescontos(props: DropDownDescontosProps) {
  const { onChange, value, ...rest } = props
  const { useQuery } = useDesconto()
  // const [temp, setTemp] = useState<DescontoModel | null | undefined>(value)
  // const [selected, setSelected] = useState<DescontoModel>()
  const {
    data: dialogData,
    isOpen,
    openDialog,
    closeDialog,
  } = useDialog<DescontoModel>({ resetDataOnClose: true })
  const { data } = useQuery({
    pagination: {
      page: 1,
      pageSize: 1000,
    },
  })
  const descontos = data?.data || []

  function handleChange(uuid: string | null) {
    const valorEncontrado = descontos.find((d) => d.uuid === uuid)
    openDialog(valorEncontrado)
  }

  function handleConfirm() {
    onChange(dialogData)
    closeDialog()
  }

  return (
    <React.Fragment>
      <TextField
        select
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        value={dialogData?.uuid || value?.uuid || ''}
        slotProps={{
          input: {
            startAdornment: value?.uuid && (
              <IconButton
                onClick={() => {
                  handleChange(null)
                }}
              >
                <Icon.Erease />
              </IconButton>
            ),
          },
        }}
        {...rest}
      >
        {descontos.map((f) => (
          <MenuItem key={f.uuid} value={f.uuid}>
            {`${f.nome} [ ${f.pdescontos} = ${f.pdesconto}% ]`}
          </MenuItem>
        ))}
      </TextField>
      <ConfirmDialog
        isOpen={isOpen}
        close={closeDialog}
        onConfirm={handleConfirm}
        onCancel={() => {
          closeDialog()
        }}
        title="Confirmação de alteração de desconto"
        message="Ao confirmar, todos os produtos do pedido serão recalculados com o novo desconto. Essa ação não poderá ser desfeita."
        confirmLabel="Sim"
      />
    </React.Fragment>
  )
}
