import { AxiosError } from 'axios'
import useCrud from 'hooks/useCrud'

export interface DescontoModel {
  uuid?: string
  codigo: number
  nome: string
  pdesconto: number
  pdescontos: string
  ativo: boolean
}

export default function useDesconto() {
  return useCrud<DescontoModel, AxiosError>('vendas/descontos', 'Desconto')
}
