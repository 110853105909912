import { onlyNumbers } from '@data-c/hooks'
import { Stack, TextField } from '@mui/material'
import CnpjCpfTextField from 'components/CnpjCpfTextField'

import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'

export default function FilterForm() {
  const { filterValues } = useFilter()
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()

  return (
    <Stack spacing={2}>
      <Stack spacing={1.5}>
        <TextField
          name="codigo"
          value={filterValues?.codigo || ''}
          label="Código"
          onChange={onChangeFilterValue}
        />
        <TextField
          name="usual"
          value={filterValues?.usual || ''}
          label="Nome"
          onChange={onChangeFilterValue}
        />
        <CnpjCpfTextField
          name="cnpjcpf"
          autoFocus
          value={filterValues?.cnpjcpf || ''}
          onChange={(e) => {
            const cnpjcpf = onlyNumbers(e.target.value)
            changeFilterValue('cnpjcpf', cnpjcpf)
          }}
          inputProps={{
            label: `CNPJ/CPF`,
          }}
        />
      </Stack>
    </Stack>
  )
}
