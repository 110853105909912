import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'
import api from 'services/api'

export interface ConfiguracoesModel {
  usa_duplicata: number
  usa_qt_volume: boolean
  usa_fator: boolean
  usa_tabpreco: number
  filtra_condicaopagamento: number
  envia_email: boolean
  edita_desconto: boolean
  edita_desconto_pedido: boolean
  edita_unitario: boolean
  usa_formapgto: number
  usa_descontos: boolean
  usa_estoque: number
  usa_limite_credito: boolean
  usa_desconto_agregado: boolean
  usa_credito_debito: boolean
  usa_promocao_progressiva: boolean
  usa_milesimal: boolean
  bloqueia_estoque: boolean
  usa_ajuste: boolean
  ajuste: number
  usa_calculo_imposto: boolean
  usa_verificacao_spc: boolean
  exibe_peso: boolean
  modulo_pedido: string
  importa_lead: boolean
  usa_grade_fechada: boolean
  nome_banco_mobile: string
  exibe_previsaocompra: boolean
  edita_observacao: boolean
  usa_pedido_pronta_entrega: boolean
  usa_baixa_automatica_pe: boolean
  usa_programa_fidelidade: 'DESATIVADO' | 'PONTO_POR_PRODUTO'
}

export async function obterConfiguracoes(): Promise<ConfiguracoesModel> {
  const response = await api.get<ConfiguracoesModel>(
    'administrador/empresa-configuracao',
  )
  return response.data
}

export async function updateConfiguracoes(
  data: ConfiguracoesModel,
): Promise<ConfiguracoesModel> {
  const response = await api.put('administrador/empresa-configuracao', data)
  return response.data
}

export function useQuery() {
  return useTQuery<ConfiguracoesModel>(
    ['EMPRESA_CONFIGURACAO'],
    () => {
      return obterConfiguracoes()
    },
    {
      staleTime: 1000 * 60,
    },
  )
}

export function useSubmit() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, ConfiguracoesModel>(updateConfiguracoes, {
    onSuccess(_: ConfiguracoesModel) {
      notifications.notifySuccess('Suas alterações foram salvas')
      queryClient.invalidateQueries(['EMPRESA_CONFIGURACAO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}
