import { ClientesModel } from 'hooks/queries/useClientes'
import Form from 'pages/Clientes/components/Form'

interface TabCadastroClienteProps {
  cliente: ClientesModel
}

export default function TabCadastroCliente(props: TabCadastroClienteProps) {
  const { cliente } = props

  return <Form view="plain" data={cliente} />
}
